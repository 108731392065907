
import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { Suspense } from "react";

const Scene = () => {
  const fbx = useLoader(FBXLoader, "ToyCar.fbx");

  return <primitive object={fbx} scale={0.005} />;
};

export default function Render3d() {
  return (
    <div className="App" style={{height: '500px', width: '100%', objectFit: 'cover'}}>
      <Canvas>
        <Suspense fallback={null}>
    
          <OrbitControls />
          <Environment preset="sunset" background />
        </Suspense>
      </Canvas>
    </div>
  );
}
