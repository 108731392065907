import React, { useEffect, useState } from 'react';
import '../assets/css/material-dashboard.css'
import HomeLf from './HomeLf';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import Background from 'three/examples/jsm/renderers/common/Background.js';
import { Center } from '@react-three/drei';

function OrdensDeServico(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [objetoTarget, setObjetoTarget] = useState();
  const [dataId, setDataId] = useState();


var count01 = 0;
const queryParameters = new URLSearchParams(window.location.search)
const parametroId = queryParameters.get("prjid")

async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];

   


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);
    };
  });


  await fetch('https://lumera-api.vercel.app/consultaOrdem', {
    method: 'POST',
    body: JSON.stringify({
      parametroId: parametroId,

    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
     .then((response) => response.json())
     .then((data) => {
        console.log(data);

        var criados = data;
        var tradd = [];
    

        tradd.push(criados.ordens);

        tradd = tradd[0];
        console.log('SSSSSS ',tradd);
        var masterTrad = [];
      for (let i = 0; i < Object.keys(criados.ordens).length; i++) {
            console.log(tradd[Object.keys(criados.ordens)[i]]);
            masterTrad.push(tradd[Object.keys(criados.ordens)[i]]);
      }

        console.log('MASTER MASTER', masterTrad);
      setFiltrados(masterTrad);
              











     })
     .catch((err) => {
        console.log(err.message);
     });



        



}


async function editar(params) {
  navigate(`/editar?projeto=${params}`);
}





async function dell(params) {


  await updateDoc(doc(db, 'clientes', `${firebaseAuth.currentUser.email}:projetos`), {
    [params]: {
        nomeDoProjeto: 'nd',
      

    }
  
  });
  navigate(0);
}






useEffect(()=> {
  master();
},[])

















    return (
        <div>


<HomeLf/>



<aside style={{top: '105px', overflow: 'hidden'}} class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" target="_blank">

        <span class="ms-1 font-weight-bold text-white">Dashboard</span>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2"/>
    <div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white active " >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">dashboard</i>
            </div>
            <span class="nav-link-text ms-1">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white "
             onClick={()=> navigate('/adicionar')}
          >
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">table_view</i>
            </div>
            <span class="nav-link-text ms-1"
           
            >Adicionar Projeto</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white ">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">receipt_long</i>
            </div>
            <span class="nav-link-text ms-1">Colaborador</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white ">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">view_in_ar</i>
            </div>

            <span class="nav-link-text ms-1">CadViewer</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white ">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
            </div>
            <span class="nav-link-text ms-1">Configurações</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white ">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">notifications</i>
            </div>
            <span class="nav-link-text ms-1">Notificações</span>
          </a>
        </li>
      

    
      </ul>
    </div>
 
  </aside>
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      <div class="container-fluid py-1 px-3">
     
    
      </div>
    </nav>

    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl  position-absolute">
                <i class="material-icons opacity-10">weekend</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Projetos criados</p>
                <h4 class="mb-0">3</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">

            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">person</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Usuários no projeto</p>
                <h4 class="mb-0">5</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
     
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">person</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Notificações</p>
                <h4 class="mb-0">5</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
       
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header p-3 pt-2">
              <div class="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                <i class="material-icons opacity-10">weekend</i>
              </div>
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Pendências</p>
                <h4 class="mb-0">2</h4>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">
    
            </div>
          </div>
        </div>
      </div>
      
      <div class="row mb-4"  style={{marginTop: '20px'}}>
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Ordens de Serviço</h6>
                  <p class="text-sm mb-0">
                 
            
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary"></i>
                    </a>
                    <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                      <li><a class="dropdown-item border-radius-md" href="javascript:;">Action</a></li>
                      <li><a class="dropdown-item border-radius-md" href="javascript:;">Another action</a></li>
                      <li><a class="dropdown-item border-radius-md" href="javascript:;">Something else here</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
     
     





              <>

                                  {filtrados.map((data, index) => {

return <> 

{

 filtrados.length > 0 ? 

<div style={{width: '100%', height: '400px', padding: '20px'}}>
  <h3>{data.nome}</h3>
  

  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Prioridade:</a> <a style={{color: 'red'}}>{data.prioridade}</a>
  </div>
  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Status:</a><a style={{color: '#eead2d'}}> {data.status} </a> 
  </div>





  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Disciplinas:</a>
  <>
                           
  <a> 
     
     
     {data.disciplinas.map((data, index) => {

      return <> 
      
      
      
      <a> {data},</a>
      
      </>
      })}
     
     

  </a>



                    </>
  </div>
  <div style={{display: 'flex', marginTop: '120px'}}>
<button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
onClick={()=> navigate(-1)}
>
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>view_in_ar </i> Voltar </button>         


        


                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                    onClick={()=> navigate(`/ordem?prjid=${parametroId}&ordem=${data.nome}`)}
                    
                    
                    >
                    <i class="material-icons opacity-10" style={{marginRight: '5px'}}>edit </i> Detalhes </button> 
</div>

</div>

                : <a style={{padding: '20px', fontSize: '20px'}}>Você não tem ordens de serviço!</a>
}





</>
})}
                    </>

       


              </div>
            </div>
          </div>
        </div>
   







      </div>
      <footer class="footer py-4  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
  
            </div>
     
          </div>
        </div>
      </footer>
    </div>
  </main>
  
  <div class="fixed-plugin">
    <a class="fixed-plugin-button text-dark position-fixed px-3 py-2">
      <i class="material-icons py-2">settings</i>
    </a>
    <div class="card shadow-lg">
      <div class="card-header pb-0 pt-3">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Material UI Configurator</h5>
          <p>See our dashboard options.</p>
        </div>
        <div class="float-end mt-4">
          <button class="btn btn-link text-dark p-0 fixed-plugin-close-button">
            <i class="material-icons">clear</i>
          </button>
        </div>

      </div>
      <hr class="horizontal dark my-1"/>
      <div class="card-body pt-sm-3 pt-0">

        <div>
          <h6 class="mb-0">Sidebar Colors</h6>
        </div>
        <a href="javascript:void(0)" class="switch-trigger background-color">
          <div class="badge-colors my-2 text-start">
            <span class="badge filter bg-gradient-primary active" data-color="primary" onclick="sidebarColor(this)"></span>
            <span class="badge filter bg-gradient-dark" data-color="dark" onclick="sidebarColor(this)"></span>
            <span class="badge filter bg-gradient-info" data-color="info" onclick="sidebarColor(this)"></span>
            <span class="badge filter bg-gradient-success" data-color="success" onclick="sidebarColor(this)"></span>
            <span class="badge filter bg-gradient-warning" data-color="warning" onclick="sidebarColor(this)"></span>
            <span class="badge filter bg-gradient-danger" data-color="danger" onclick="sidebarColor(this)"></span>
          </div>
        </a>
 
        <div class="mt-3">
          <h6 class="mb-0">Sidenav Type</h6>
          <p class="text-sm">Choose between 2 different sidenav types.</p>
        </div>
        <div class="d-flex">
          <button class="btn bg-gradient-dark px-3 mb-2 active" data-class="bg-gradient-dark" onclick="sidebarType(this)">Dark</button>
          <button class="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-transparent" onclick="sidebarType(this)">Transparent</button>
          <button class="btn bg-gradient-dark px-3 mb-2 ms-2" data-class="bg-white" onclick="sidebarType(this)">White</button>
        </div>
        <p class="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
 
        <div class="mt-3 d-flex">
          <h6 class="mb-0">Navbar Fixed</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input class="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" onclick="navbarFixed(this)"/>
          </div>
        </div>
        <hr class="horizontal dark my-3"/>
        <div class="mt-2 d-flex">
          <h6 class="mb-0">Light / Dark</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input class="form-check-input mt-1 ms-auto" type="checkbox" id="dark-version" onclick="darkMode(this)"/>
          </div>
        </div>
        <hr class="horizontal dark my-sm-4"/>
        <a class="btn bg-gradient-info w-100" href="https://www.creative-tim.com/product/material-dashboard-pro">Free Download</a>
        <a class="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/overview/material-dashboard">View documentation</a>
        <div class="w-100 text-center">
          <a class="github-button" href="https://github.com/creativetimofficial/material-dashboard" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/material-dashboard on GitHub">Star</a>
          <h6 class="mt-3">Thank you for sharing!</h6>
          <a href="https://twitter.com/intent/tweet?text=Check%20Material%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard" class="btn btn-dark mb-0 me-2" target="_blank">
            <i class="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
          </a>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-dashboard" class="btn btn-dark mb-0 me-2" target="_blank">
            <i class="fab fa-facebook-square me-1" aria-hidden="true"></i> Share
          </a>
        </div>
      </div>
    </div>
  </div>





        </div>
    )
}
export default OrdensDeServico;