

import { useEffect, useState } from 'react';
import './Login.css';
import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
function Entrar() {


  const [emailLL, setEmailLL] = useState()
  const [passwordDD, setPasswordDD] = useState();

  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const [logadoM, setLogadoM] = useState(false);
  const [reseter, setReseter] = useState(false);
  const [emailRst, setEmailRst] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const toHome = queryParameters.get("tohome")





  async function resetEmail(params) {
    const auth = getAuth();
sendPasswordResetEmail(auth, emailRst)
  .then(() => {
    alert(`Email de Redefinição enviado para ${emailRst}`);
    navigate('/')
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    alert(error.message);
    // ..
  });
  }


async function irPara(params) {
 
  try {
    await signInWithEmailAndPassword(firebaseAuth, emailLL, passwordDD);
    navigate('/main');
  } catch (error) {
    setMsg(error.code);
  }

}
const auth = getAuth();
useEffect(()=> {

    if(toHome == 'logout'){
      onAuthStateChanged(auth, async (user) => {
        if (user) {
    
          const uid = user.uid;
    
    setLogadoM(false);
    
        } else {
          setLogadoM(true)
    
        }
      });
    } else {
   //   navigate('/');
    }
 



}, [])





  return (
   <div>


{
    1 == 1 ?
// logadoM ?

  <div class="publicar" style={{width: '100%'}}>

{!reseter ?
  <h1 class="titulo" style={{color: 'black'}}>Login</h1>
  :
  <h1 class="titulo">Redefinir Senha</h1>

}
{
  !reseter ?
  <>
<input class="criar"

onChange={(e) => setEmailLL(e.target.value)}
value={emailLL}
style={{paddingLeft: '10px', width: '250px', borderColor: 'black', color: 'black'}} placeholder="Email"></input>
<input 

type='password'
onChange={(e) => setPasswordDD(e.target.value)}
value={passwordDD}
class="criar" style={{paddingLeft: '10px', width: '250px', borderColor: 'black', color: 'black'}} placeholder="Senha"></input>
<button 
onClick={()=> irPara()}
className='button' style={{backgroundColor: 'rgb(222,222,222)', padding: '10px 30px', borderRadius: '12px'}}>Logar</button>
</>
  : 
  <>
<input 

type='email'
onChange={(e) => setEmailRst(e.target.value)}
value={passwordDD}
class="criar" style={{paddingLeft: '10px', width: '250px' }} placeholder="Email"></input>
<button 
onClick={()=> resetEmail()}
className='button'>Enviar</button>
</>
}


     {
                  msg.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '10px', marginLeft: '0px', backgroundColor: '#999'}}>{msg.replace('auth/', '')}</a>          
                  : ''
              }
{
  !reseter &&
  <a
  onClick={()=> setReseter(true)}
  style={{textDecoration: 'underline', padding: '10px 0px', fontSize: '14px', color: '#555', cursor: 'pointer'}}>Esqueci a senha</a>

}
         
         
              </div>

: 
<div class="publicar">

<h1 class="titulo">Você já está logado!</h1>


<button 
onClick={()=> navigate('/')}
className='button'
style={{marginTop: '50px'}}
>Voltar</button>
     {
                  msg.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '10px', marginLeft: '0px', backgroundColor: '#999'}}>{msg.replace('auth/', '')}</a>          
                  : ''
              }
              
              </div>


}




</div>
  );
}

export default Entrar;






