

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Home from './Home';
import Adicionar from './components/Adicionar';
import MeusProjetos from './MeusProjetos';
import Projeto from './components/Projeto';
import Uploader from './components/Upload';
import Menu from './components/Menu';
import Editar from './components/Editar';
import Colaborador from './components/Colaborador';
import Participante from './components/Participante';
import Main from './components/MainProject';
import CriarOrdem from './components/CriarOredem';
import CriarMensagem from './components/CriarMensagem';
import OrdensDeServico from './components/OrdensDeServico';
import Login from './components/Login';
import Entrar from './components/Login';
import EnviarMensagens from './components/EnviarMensagens';
import Ordem from './components/Ordem';
import UploadMaster from './components/UploadMaster';



function App() {
  return (
    <div>
     
         <BrowserRouter>
     <Routes>
  
     <Route exact path="/" element={<Home/>}/>
     <Route exact path="/register" element={<Register/>}/>     
     <Route exact path="/adicionar" element={<Adicionar/>}/>     
     <Route exact path="/meusprojetos" element={<MeusProjetos/>}/>     
     <Route exact path="/projeto" element={<Projeto/>}/>     
     <Route exact path="/teste" element={<Uploader/>}/>     
     <Route exact path="/ordens" element={<OrdensDeServico/>}/>     
     <Route exact path="/ordem" element={<Ordem/>}/>     
     <Route exact path="/login" element={<Entrar/>}/>     
     <Route exact path="/menu" element={<Menu/>}/>  
     <Route exact path="/uploadMaster" element={<UploadMaster/>}/>  
     <Route exact path="/main" element={<Main/>}/>  
     <Route exact path="/criarordem" element={<CriarOrdem/>}/>  
     <Route exact path="/participante" element={<Participante/>}/>  
     <Route exact path="/enviarmensagens" element={<EnviarMensagens/>}/>  
     <Route exact path="/editar" element={<Editar/>}/>  
     <Route exact path="/colaborador" element={<Colaborador/>}/>  
     <Route exact path="/msgenv" element={<CriarMensagem/>}/>  



      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
