
import './Register.css';
import React, { useEffect, useState } from 'react'
import { db, firebaseAuth, storage } from "../utils/firebase-config";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import HomeLf from './HomeLf';
//import Header from './Header';

// REGISTER MASTER

function Adicionar() {

    const navigate = useNavigate();



    const [deslogado, setDeslogado] = useState(false);



    
    const [stepby, setStepby] = useState('none')
    const [page01, setPage01] = useState('none');
    const [page02, setPage02] = useState('none');
    const [page03, setPage03] = useState('block');
    const [page04, setPage04] = useState('none');
    const [page05, setPage05] = useState('none');
    const [page06, setPage06] = useState('none');
    const [page07, setPage07] = useState('none');



    const [emaill, setEmaill] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaCnf, setSenhaCnf] = useState('');
    const [nome, setNome] = useState('');
    const [cidade, setCidade] = useState('');
    const [idade, setIdade] = useState('');
    const [telefone, setTelefone] = useState('');
    const [sobre, setSobre] = useState('');



    const [mecanica, setMecanica] = useState(false);
    const [eletrica, setEletrica] = useState(false);
    const [eletronica, setEletronica] = useState(false);
    const [construcao, setConstrucao] = useState(false);
    const [construcaoCivil, setConstrucaoCivil] = useState(false);
    const [terceirizado, setTerceirizado] = useState(false);
    const [proprio, setProprio] = useState(false);
    const [conjunto, setConjunto] = useState(false);
    const [projetoNome, setProjetoNome] = useState('');
    const [comentariosIniciais, setComentariosIniciais] = useState('');
    const [descricao, setDescricao] = useState('');





    const [countMp, setCountMp] = useState(1);
    const [countMpC, setCountMpC] = useState(0);


    const [imageUpload, setImageUpload] = useState();
    const [imageUpload2, setImageUpload2] = useState();
    const [imageUpload3, setImageUpload3] = useState();
    const [imageUpload4, setImageUpload4] = useState();
    const [videoUpload, setVideoUpload] = useState();




    const [url01, setUrl01] = useState('');
    const [url02, setUrl02] = useState('');
    const [url03, setUrl03] = useState('');
    const [url04, setUrl04] = useState('');
    const [url05, setUrl05] = useState('');

    const [msgs, setMsgS] = useState('');
    const [msgs2, setMsgS2] = useState('');
    const [msgs3, setMsgS3] = useState('');
    const [msgs4, setMsgS4] = useState('');
    const [msgs5, setMsgS5] = useState('');
    const [msgs6, setMsgS6] = useState('');



    const [current, setCurrent] = useState('');
    const [dataA, setDataA] = useState([]);
    const [mapeA, setMapeA] = useState([1]);
    const [mapeC, setMapeC] = useState([]);

    const [constValues, setConstValues] = useState([]);

  
    var trad = [];
    var tradC = [];
    function master(params) {

        

        var objMaster = [];
        onAuthStateChanged(firebaseAuth, async (currentUser) => {
            if (currentUser) {
            console.log('current ', currentUser.email);
                setCurrent(current);
            };
          });
        
        
        }








    async function setMaster(params) {
        
        if(params == 1){
        

          
              
              if(senha == senhaCnf){
            
              try {
              await createUserWithEmailAndPassword(firebaseAuth, emaill, senha);
               setPage01('none');
               setPage02('block');
               setPage03('none');
               setPage04('none');
              } catch (err) {
                setMsgS(err.code);
              }
            } else {
              setMsgS('Senhas não correspondem!')
            }
        }
        if(params == 2){
              if(nome.length > 3 && cidade.length> 3 && idade.length > 1 && telefone.length > 8 
              
                ) {
                  setPage01('none');
                  setPage02('none');
                  setPage03('block');
                  setPage04('none');
                } else {

                  if(nome.length <= 3){setMsgS2('Preencha seu nome!') } else {setMsgS2('')}
                  if(cidade.length <= 3){setMsgS3('Preencha sua cidade!') } else {setMsgS3('')}
                  if(idade.length <= 1){setMsgS4('Preencha sua idade!') } else {setMsgS4('')}
                  if(telefone.length <= 7){setMsgS5('Preencha seu telefone!') } else {setMsgS5('')}
                  if(sobre.length <= 100){setMsgS6('Sua bio deve ter mais de 100 caracteres') } else {setMsgS6('')}

                }
   
        }

        if(params == 3){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('block');
        }
        if(params == 4){

          //  setDD();
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('block');
            
        }

        if(params == 5){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('block');
     
        }

        if(params == 6){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('none');
            setPage06('block');
        }
        if(params == 7){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('none');
            setPage06('none');
            setPage07('block');
        }

    }






    async function finishMaster(params) {

        var disciplinasObj = [];
     
        for (let i = 0; i < document.querySelectorAll('.disciplinas').length; i++) {
            disciplinasObj.push(document.querySelectorAll('.disciplinas')[i].value);
            
        }

        var colaboradoresMaster = [];
        for (let i = 0; i < document.querySelectorAll('.dadoscolaboradores').length; i++) {
            colaboradoresMaster.push(`${document.querySelectorAll('.dadoscolaboradores')[i].querySelector('input').value} ###master ${document.querySelectorAll('.dadoscolaboradores')[i].querySelectorAll('select')[0].value}  ###master ${document.querySelectorAll('.dadoscolaboradores')[i].querySelectorAll('select')[1].value}`)
        }

        var colaboradoresId = [];
        for (let i = 0; i < document.querySelectorAll('.dadoscolaboradores').length; i++) {
            colaboradoresId.push(document.querySelectorAll('.dadoscolaboradores')[i].querySelector('input').value);
        }


        var nomeDaOrdem = 'ordem001';

        // `mensagem ######disciplinas/eletrica/hidraulica ######quemmandou/${quemMandou}`] 
        var objetoMsgs = {
           // [nomeDaOrdem]: { nome: '', status: '', prioridade: '', disciplinas: [], mensagens: []},
         
        }
          
            var randomName = Math.random();
            randomName = String(randomName);
            randomName = randomName.replace('0.', '');
        await setDoc(doc(db, 'projetos', randomName), {
            
      
                nomeDoProjeto: projetoNome,
                idProjeto: randomName,
                disciplinas: disciplinasObj,
                colaboradores: colaboradoresMaster,
                mecanica: mecanica,
                eletrica: eletrica,
                emailsParticipantes: colaboradoresId,
                criador: firebaseAuth.currentUser.email,
                eletronica: eletronica,
                construcao: construcao,
                construcaoCivil: construcaoCivil,
                conjunto: conjunto,
                ordens: [],
                proprio: proprio,
                terceirizado: terceirizado,
                descricao: descricao,
                comentariosIniciais: comentariosIniciais,
                url01: url01,
                urn: ''

            

          });
         
          
          setTimeout(() => {
            navigate('/main');
          }, 1000);

    }

 




   async function enviarImagensVideo(params) {

            finishMaster();
            
        
    }





    const uploadFile = async (params) => {
        if (!params) return;



                var paramsNome = params.name;
                var targetExtensao = paramsNome.length;
                var targetFinal = targetExtensao - 3;
                var extensao = paramsNome.slice(targetFinal, targetExtensao);


             
             





        const imageRef = ref(storage, `clientesMidias/${firebaseAuth.currentUser.email}/model01.`+extensao);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl01(url);
       
            });
        }  );

    };

  
    const uploadFile2 = (params) => {
        if (!params) return;
    

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto02'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl02(url);
            });
        }  );

    };


    const uploadFile3 = (params) => {
        if (!params) return;
      

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto03'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl03(url);
            });
        }  );

    };


    const uploadFile4 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto04'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl04(url);
            });
        }  );

    };

    const uploadFile5 = (params) => {
        if (!params) return;
        setVideoUpload(params);

        const imageRef = ref(storage, `clientesMidias/${emaill}/${params.name}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl05(url);
            });
        }  );

    };


      
    function mapear() {
            
        setCountMp(countMp + 1);
  
        for (let i = 0; i < countMp; i++) {
            trad.push(i);
            
        }
        trad.push(countMp);
        console.log(trad);
        setMapeA(trad);
    }


    function mapearC() {
            
     
         
         
        if(countMpC == 0){
            tradC.push(0);
            setMapeC(tradC);
            setCountMpC(countMpC + 1);
        } else {

            setCountMpC(countMpC + 1);

            for (let i = 0; i < countMpC; i++) {
                tradC.push(i);
                
            }
           
                tradC.push(countMpC);
            
          
            console.log(tradC);
            setMapeC(tradC);





        }

       
      
    }



        function disciRecorder() {
            
            var disciObjs = [];
            for (let i = 0; i < document.querySelectorAll('.disciplinas').length; i++) {
                disciObjs.push(document.querySelectorAll('.disciplinas')[i].value);
            }
            setConstValues(disciObjs);
            setMaster(3);
        }




    useEffect(()=> {

        master();

    },[])


  return (
  
<>
<HomeLf/>

  <div class="card card-1" style={{marginTop: '-100px', display: page01}}>
  <div class="card-heading"></div>
  <div class="card-body">
      <h2 class="title">Criar conta</h2>
   
          <div class="input-group"  style={{borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}`}}>
              <input class="input--style-1" type="text" placeholder="Email" name="Email"
               onChange={(e) => setEmaill(e.target.value)  }
              />
          </div>
          <div class="row row-space">
              <div class="col-2">
                  <div class="input-group"  style={{borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}`}}>
                      <input class="input--style-1 js-datepicker" type="password" placeholder="Senha" name="Senha"
                             onChange={(e) => setSenha(e.target.value)  }
                      />
                      <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                  </div>
              </div>

          </div>

      

          <div class="row row-space">
              <div class="col-2">
                  <div class="input-group" style={{borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}`}}>
                      <input class="input--style-1 js-datepicker" type="password" 
                      onChange={(e)=> setSenhaCnf(e.target.value) }
                      placeholder="Confirmar senha" name="Confirmar senha"/>
                      <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                
                
                
                  </div>
                  
              </div>
            
          </div>
          <div class="row row-space col-4">
              
       

              <button class="btn button hoverbtn"
            style={{minWidth: '200px', backgroundColor: '#4646D6'}}
               onClick={()=> setMaster(1)}
             
               >Cadastrar-se</button>
                {
                    msgs.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginTop: '15px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs.replace('auth/', '')}</a>          
                    : ''
                } 
                    
            
          </div>




      
  
  </div>
</div>
  
  

  
   

   

<div class="card card-1" style={{marginTop: '-100px', display: page02}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Dados Pessoais</h2>
           
                    <div class="input-group" style={{borderColor: `${msgs2.length > 2 ? 'red' : '#CCCCCC'}`}}>
                        <input
                          onChange={(e) => setNome(e.target.value)  }
                        class="input--style-1" type="text" placeholder="Nome" name="name"/>
                    </div>
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" style={{borderColor: `${msgs3.length > 2 ? 'red' : '#CCCCCC'}`}}>
                                <input
                                 onChange={(e) => setCidade(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Cidade" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>

                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" style={{borderColor: `${msgs4.length > 2 ? 'red' : '#CCCCCC'}`}}>
                                <input
                                 onChange={(e) => setIdade(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Idade" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>

                    
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" style={{borderColor: `${msgs5.length > 2 ? 'red' : '#CCCCCC'}`}}>
                                <input 
                                 onChange={(e) => setTelefone(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Telefone" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>
                    </div>




                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" style={{borderColor: `${msgs6.length > 2 ? 'red' : '#CCCCCC'}`}}>
                                <input 
                                maxlength="297"
                                 onChange={(e) => setSobre(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Site" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>
                    </div>








                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                          style={{minWidth: '200px', height: '45px', color: 'white', backgroundColor: '#4646D6'}}
                         type="submit"
                         onClick={()=> finishMaster()}
                         >Continuar</button>

{
                    msgs2.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs2.replace('auth/', '')}</a>          
                    : ''
                }
                 <br/>
                 {
                    msgs3.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs3.replace('auth/', '')}</a>          
                    : ''
                }
                  <br/>
                 {
                    msgs4.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs4.replace('auth/', '')}</a>          
                    : ''
                }
                    <br/>
                 {
                    msgs5.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs5.replace('auth/', '')}</a>          
                    : ''
                }
  <br/>
                 {
                    msgs6.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs6.replace('auth/', '')}</a>          
                    : ''
                }




                    </div>

            
            </div>
        </div>
 




        <div class="card card-1" style={{marginTop: '-100px', display: page03}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Informações iniciais</h2>
           
                <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group">
                                <input
                                style={{border: 'solid #CCCCCC', borderRadius: '10px', paddingLeft: '10px'}}
                                 onChange={(e) => setProjetoNome(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Nome do projeto" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>
                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Disciplinas :</h5>


                            <>
                                  {mapeA.map((data, index) => {

return <> 



<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}> 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="" className="disciplinas"
   onChange={(e) => setMecanica(e.target.checked)  }
  />
</div>
                         
                         
                         </div> 

</>
})}
                    </>

<a style={{color: 'green', fontSize: '28px', marginLeft: '5px', cursor: 'pointer'}}
onClick={()=> mapear()}
>+</a> 





                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>









                        </div>


                    




                    </div>



                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Tipo de projeto:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Terceirizado 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setTerceirizado(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Próprio 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setProprio(e.target.checked)  }
  />
</div>

</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Em conjunto 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setConjunto(e.target.checked)  }
  />
</div>



</div>
                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>















                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                          style={{minWidth: '200px', color: 'white',backgroundColor: '#4646D6'}}
                         type="submit"
                         onClick={()=> disciRecorder()}
                         >Continuar</button>

                      
                      
                    </div>
           
             
            
            </div>
        </div>
 






        <div class="card card-1" style={{marginTop: '-100px', display: page04}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Colaboradores</h2>
           
                <div class="row row-space">
                <div class="col-7">
                        <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Colaboradores:</h5>
                        {mapeC.map((data, index) => {

return <> 
                        <div class={`input-group dadoscolaboradores`} style={{margin: '0px', marginTop: '10px'}}>
                           

                           <>




<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}> 
                        
                        <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
 <input type="" className="colaboradores" placeholder="email@email.com"
  onChange={(e) => setMecanica(e.target.checked)  }
 />
</div>
                  
                        
                        </div> 
                        <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}> 
                        
                        <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
                        <select name="cars" id="cars" style={{border: 'none', padding: '0px 10px'}}>
  
  


                        {constValues.map((data, index) => {

return <> 
                     <option value={data}>{data}</option>

                           </>
})}


</select>
</div>       
                        
                        </div> 

                        <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}> 
                        
                        <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
                        <select name="cars" id="cars" style={{border: 'none', padding: '0px 10px'}}>
  <option value="Administrador"  style={{border: 'none'}}>Administrador</option>
  <option value="Gerente">Gerente</option>
  <option value="Engenheiro">Engenheiro</option>
  <option value="Prestador de serviços">Prestador de serviços</option>
</select>
</div>
                  
                        
                        </div> 

                   </>







                               <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                           </div>



                           </>
})}


<a style={{color: 'green', fontSize: '19px', marginLeft: '5px', cursor: 'pointer'}}
onClick={()=> mapearC()}
>Adicionar colaborador +</a> 

                            
                        </div>


                    </div>
               


         













                    <div class="row row-space col-4" style={{paddingTop: '30px'}}>
                        
                 

                        <button class="btn button"
                          style={{minWidth: '200px', color: 'white',backgroundColor: '#4646D6'}}
                         type="submit"
                         onClick={()=> setMaster(4)}
                         >Continuar</button>

                      
                      
                    </div>
           
             
            
            </div>
        </div>
 



        <div class="card card-1" style={{marginTop: '-100px', display: page05}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Informações do projeto</h2>
           
    

                <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" style={{borderColor: `${msgs3.length > 2 ? 'red' : '#CCCCCC'}`}}>
                                <input
                                 onChange={(e) => setDescricao(e.target.value)  }
                                 style={{backgroundColor: 'rgba(111,111,111,0.2)', maxWidth: '400px'}}
                                class="input--style-1 js-datepicker" type="text" placeholder="Descrição do projeto" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>


                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" style={{borderColor: `${msgs3.length > 2 ? 'red' : '#CCCCCC'}`}}>
                                <input
                                 style={{backgroundColor: 'rgba(111,111,111,0.2)', maxWidth: '400px'}}
                                 onChange={(e) => setComentariosIniciais(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Comentários iniciais" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>





       
















                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                          style={{minWidth: '200px', color: 'white', backgroundColor: '#4646D6'}}
                         type="submit"
                         onClick={()=> finishMaster()}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 




        <div class="card card-1" style={{marginTop: '-100px', display: page06}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Arquivos</h2>
                        <a style={{color: 'red'}}>{'*Arquivos suportados( dwg, fbx, obj )'}</a> <br/>
                    <div class="input-group" style={{width: '400px'}} >
                  <a>Arquivo 1</a>  <input
   type="file"
   onChange={(event) => {

    setImageUpload(event.target.files[0]);
    uploadFile(event.target.files[0]);
   }}
   />
                    </div>



                   
            
         
                  




                  




                 







                    <div class="row row-space col-4">
                        

         <button class="btn button"
         style={{minWidth: '200px', color: 'white', backgroundColor: '#4646D6'}}
         type="submit"
         onClick={()=> finishMaster()}
         >Continuar</button> 
  
                      
                      
                    </div>
      
            
            </div>
        </div>


        <div class="card card-1" style={{marginTop: '-100px', display: page07}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Fotos</h2>
           
                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 1 (Perfil)</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload(event.target.files[0]);
   }}
   />
                    </div>

                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 2</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload2(event.target.files[0]);
   }}
   />
                    </div>
            

                    
                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 3</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload3(event.target.files[0]);
   }}
   />
                    </div>





                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 4</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload4(event.target.files[0]);
   }}
   />
                    </div>





            







                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                         type="submit"
                         style={{ color: 'white', backgroundColor: '#4646D6'}}
                         onClick={()=> enviarImagensVideo()}
                         >Finalizar Cadastro</button>

                      
                      
                    </div>

            
            </div>
        </div>

        </>
  );
}

export default Adicionar;







