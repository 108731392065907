import React, { useEffect, useState } from 'react';
import AppM from '../AppM';
import HomeLf from './HomeLf';
import axios from 'axios';

function Projeto() {


  const queryParameters = new URLSearchParams(window.location.search)
  const type = queryParameters.get("urn");
  const [tokenT, setTokenT] = useState('');
  console.log(type);


  function fetchMaster(params) {
    

     
  
       const options = {
        url: 'https://lumera-api.vercel.app/tokenviewer',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        }
     
      };
      axios(options)
        .then(response => {
          setTokenT(response.data.access_token);
        });




}


useEffect(()=> {


  fetchMaster();

  
},[])




return ( <>


<HomeLf/>

{tokenT.length > 2 ?
  <AppM token={tokenT} urn={type} />
:
<h2>Carregando...</h2>
}


</>
); }
export default Projeto;