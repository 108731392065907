
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth"
import { collection, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD00WZthn6siJYoO8GK8KfaXQOL8Oa_LEM",
  authDomain: "baseacompanhante.firebaseapp.com",
  databaseURL: "https://baseacompanhante-default-rtdb.firebaseio.com",
  projectId: "baseacompanhante",
  storageBucket: "baseacompanhante.appspot.com",
  messagingSenderId: "393105962860",
  appId: "1:393105962860:web:1e3473f67964b12bc4e549"
};

const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);

export const db = getFirestore(app)

export const storage = getStorage(app)

export const collectionRef = collection(db, "clientes")