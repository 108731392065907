import logo from './img/logo.png';
import carousel1 from './img/carousel-1.jpg';
import carousel2 from './img/carousel-2.jpg';
import cat1 from './img/cat-1.jpg';
import cat2 from './img/cat-2.jpg';
import cat3 from './img/cat-3.jpg';
import cat4 from './img/cat-4.jpg';

import testimonial1 from './img/testimonial-1.jpg';
import testimonial2 from './img/testimonial-2.jpg';
import testimonial3 from './img/testimonial-3.jpg';
import testimonial4 from './img/testimonial-4.jpg';


import about from './img/about.jpg';

import './App.css';
import Render3d from './components/Render3d';
import { Navigate, useNavigate } from 'react-router-dom';
import { count, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db, firebaseAuth } from './utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import Viewer from './Viewer';
import AppM from './AppM';



function MeusProjetos() {
    const navigate = useNavigate();


    const [current, setCurrent] = useState('');
    const [dataA, setDataA] = useState([]);

var count01 = 0;


function master(params) {

        

    var objMaster = [];
    var objMaster2 = [];
onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {setCurrent(currentUser.email);
    console.log('current ', current);
    const classRef = doc(db, "clientes", currentUser.email+':projetos');
    const classSnap = await getDoc(classRef);
    console.log("Class data:", classSnap.data());
    objMaster = classSnap.data();
    if(classSnap.data() != undefined){

    
    if(Object.entries(classSnap.data()).length > 0){
      
        console.log('O)BJJ ',Object.entries(classSnap.data()));

        setDataA(Object.entries(classSnap.data()));
    } }
    };
  });


}







useEffect(()=> {
    master();
},[])



  return (
    <div className="App">
      
      {
        // APP MASTER
      }

     


    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
       <img src={logo} style={{height: '50px', marginLeft: '30px'}} />
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
                <a onClick={()=> navigate('/')} className="nav-item nav-link active">Home</a>
                <a 

                className="nav-item nav-link">Sobre</a>
                <a href="courses.html" className="nav-item nav-link">Commo Usar</a>
                <div className="nav-item dropdown">
                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Exemplos</a>
                    <div className="dropdown-menu fade-down m-0">
                        <a href="team.html" className="dropdown-item">Hierarquia</a>
                        <a href="testimonial.html" className="dropdown-item">Categorias</a>
                        <a href="404.html" className="dropdown-item">Todo o Projeto</a>
                    </div>
                </div>
                <a className="nav-item nav-link"
                onClick={()=> navigate('/meusprojetos')}
                >Projetos</a>
            </div>
            <a href="" 
            onClick={()=> navigate('/register')}
            style={{backgroundColor: 'rgb(70, 70, 214)'}} className="btn btn-primary py-3 px-lg-5 d-none d-lg-block">Cadastro/Login<i className="fa fa-arrow-right ms-3"></i></a>
        </div>
    </nav>

  

    <div className="container-xxl py-5" style={{marginTop: '50px'}}>
        <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">Projetos</h6>
                <h1 className="mb-5">Projetos Criados</h1>
            </div>
            <div className="row g-4">
     
                {console.log('MASTER MASTER', dataA)}

                {dataA.length > 1 ?
                    <>
                                  {dataA.map((data, index) => {

return <> 

{data[1].nomeDoProjeto.length > 2 ? 

<div 
onClick={()=> navigate(`/projeto?urn=${data[1].urn}`)}
className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item text-center pt-3">
                        <div className="p-4">
                            <i className="fa fa-3x fa-wrench text-primary mb-4"></i>
                            <h5 className="mb-3">{data[1].nomeDoProjeto}</h5>
                            <p>{data[1].descricao}</p>
                        </div>
                    </div>
                </div>
                : ''
}





</>
})}
                    </>
                    : 
                    <div>
                    <h3>Você não tem projetos criados!</h3>
                    <button
                    onClick={()=> navigate('/adicionar')}
                    style={{color: 'white', padding: '10px', width: '200px', backgroundColor: '#4646D6', marginTop: '20px'}}>Criar</button>
                    </div>
                }
  
             
            </div>
        </div>
    </div>






    <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s"
     
    >
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Links Rápidos</h4>
                    <a className="btn btn-link" href="">Sobre</a>
                    <a className="btn btn-link" href="">Contato</a>
                    <a className="btn btn-link" href="">Termos de Uso</a>
                    <a className="btn btn-link" href="">Pacotes</a>
                    <a className="btn btn-link" href="">SAC</a>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Contato</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Rua tal de tal</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+55 11 888888</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>dio@email.com</p>
                    <div className="d-flex pt-2">
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-youtube"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
            
     
            </div>
        </div>
        <div className="container">
            <div className="copyright">
         
            </div>
        </div>
    </div>
   
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>


       




    </div>
  );
}

export default MeusProjetos;
