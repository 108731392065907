import { useNavigate } from "react-router-dom";


function Uploader(params) {
    

    const navigate = useNavigate();

return (
    <form action="http://localhost:4300/api/forge/datamanagement/bucket/upload" enctype="multipart/form-data" method="post">
    <input type="file" name="fileToUpload"/>
    <input style={{display: 'none'}} type="text" value="eyJhbGciOiJSUzI1NiIsImtpZCI6IlhrUFpfSmhoXzlTYzNZS01oRERBZFBWeFowOF9SUzI1NiIsInBpLmF0bSI6ImFzc2MifQ.eyJzY29wZSI6WyJkYXRhOnJlYWQiLCJkYXRhOndyaXRlIiwiZGF0YTpjcmVhdGUiLCJidWNrZXQ6Y3JlYXRlIiwiYnVja2V0OnJlYWQiXSwiY2xpZW50X2lkIjoiaHVoMGM2UG1oTDIxdVpoakYxYzVUUUVVenFqWFhobFVNdGhhV3hBZVdpT0hHUXh5IiwiaXNzIjoiaHR0cHM6Ly9kZXZlbG9wZXIuYXBpLmF1dG9kZXNrLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXV0b2Rlc2suY29tIiwianRpIjoibmo4UnoxR3F3djRSZGdGV3BSbG9NQnZqMWhsZHhFaENUQndhNDFrV2ZHYWxhSUhnTzVMZDQwYjRVcFVmTjZtRSIsImV4cCI6MTczMTQ0NjE0M30.eI5SYPumXfCs7e7WV4l7kiiuy--vV78JbmTJbXxlIr0g-GnS85zpPh9L6YqVbrb9dz-xG4wVRVvXGhG4a89iYcIoSxQjxuNdIdrDMJYhLeu4QGTcV3jl-0DGV5EdbybHN6kYysrQBJG1QDpMrhINUR4wKsHIzTm-GoF1_HWQaYI1gQeiqjIreurjRGI-sqLvK0r_J5-9EYIxX9SfX7HL6LKfnJnzzaczPsJWIHBhiQDVoJy6GfMwntq1FECJuVUc8mkrDXTW_0KFlacp9nWjtebREOpn5LNjZMBwrxh9fmwcxQaf2j6CQXrUOH0yBn2FySMBEm6hkWQX_h4vlr6-0A" name="tokentoken"/>
    <button type="submit">Submit</button>
</form>
)

}

export default Uploader;